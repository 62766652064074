(function ($) {
	$(window).load(function () {
		// owl-carousel
		$(".owl-carousel:not(.slider)").owlCarousel({
			items: 1,
			loop: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// animation
			animateIn: "fadeIn",
			animateOut: "fadeOut",

			// navigation
			nav: true,
			navText: [
				'<i class="fas fa-chevron-left"></i>',
				'<i class="fas fa-chevron-right"></i>',
			],
			dots: true,

			// drag
			mouseDrag: false,
		});

		// slider
		$(".slider").owlCarousel({
			items: 1,
			margin: 15,
			loop: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// navigation
			nav: true,
			navText: [
				'<i class="fas fa-chevron-left"></i>',
				'<i class="fas fa-chevron-right"></i>',
			],
			dots: true,

			// drag
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 2,
				},
				768: {
					items: 2,
				},
				992: {
					items: 3,
				},
				1200: {
					items: 3,
				},
			},
		});

		// form-sent
		setTimeout(() => {
			var locationSearch = "" + document.location.search;
			if (
				(-1 === locationSearch.indexOf("form[sent]") &&
					-1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
				-1 === document.location.hash.indexOf("#form")
			) {
				return;
			}

			var $headerHeight = $(".header");
			var $message = $(".form-success-message");

			if (!$headerHeight.length || !$message.length) {
				return;
			}

			var currentScrollTop = $("html").scrollTop();
			var newScrollTop =
				$message.offset().top - $headerHeight.outerHeight() - 30;
			if (newScrollTop >= currentScrollTop) {
				return;
			}

			$("html, body").animate({
				scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
			});
		}, 500);
	});

	$(document).ready(function () {
		// sticky
		require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

		// fancybox
		$("a[data-fancybox]").fancybox({
			buttons: [
				//"zoom",
				//"share",
				//"slideShow",
				//"fullScreen",
				//"download",
				"thumbs",
				"close",
			],
		});

		// scroll-to-content
		$(".js-3w-scroll-to-content").on("click", function (event) {
			event.preventDefault();
			var identifier = $(this).attr("data-target");
			var $element = $(identifier);

			if ($element) {
				$("html, body").animate({ scrollTop: $element.offset().top }, 500);
			}
		});

		// faq fix
		let faqlinks = document.querySelectorAll(".faq-category a");
		faqlinks.forEach((link) => {
			if (link.id) {
				link.id = "";
				link.href = "#";
			}
		});

		// prevent scrolling on #TommyBookingSupport page
		$(window).on("hashchange", function (e) {
			if ($("#TommyBookingSupport").length > 0) {
				e.preventDefault();

				// disable scrolling
				$("body").css("overflow", "hidden");

				// set current scroll position
				$("html, body").animate({
					scrollTop: $("#TommyBookingSupport").offset().top - 120,
				});

				// enable scrolling
				setTimeout(() => {
					$("body").css("overflow", "auto");
				}, 10);
			}
		});

		// Random icons
		if ( $('ul.random-icons').length > 0 ) {
			$('ul.random-icons').each(function () {
				const iconsCount = $(this).find('li').length;
				const randomIndex = Math.floor(Math.random() * iconsCount);
				// console.log('iconsCount', iconsCount)
				// console.log('randomIndex', randomIndex)
				$(this).find('li').eq(randomIndex).addClass('active');
			});
		}

	});
})(jQuery);

// mobile menu

let menuBtn = document.querySelector(".navbar-toggler");
let menu = document.querySelector(".menu-mobile");
let status = false;
if (menu && menuBtn) {
	menuBtn.addEventListener("click", () => {
		status = !status;
		menu.classList.toggle("collapse");
		menuBtn.ariaExpanded = status;
	});
}

// usp switcher
let items = document.querySelectorAll("ul.top-usp-list li");
items[0].classList.add("active");
if (items) {
	let currentIndex = 0;
	function toggleActiveClass() {
		items[currentIndex].classList.remove("active");
		currentIndex = (currentIndex + 1) % items.length;
		items[currentIndex].classList.add("active");
	}
	setInterval(toggleActiveClass, 5000);
}
